import React, { useContext, useState } from 'react';
import { Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import "./style.css";
import AuthContext from '../../Context/AuthContext';


const LoginPage = () => {

const{email,password,setEmail,setPassword,setBranch}=useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
 
  
    const requestBody = {
      uniqueid: email,
      uniquestr: password,
    };
  
    try {
      console.log('Sending login request with data:', requestBody);
  
      const response = await fetch("http://118.189.74.190:1015/api/uservalidation", {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=utf-8',
        },
        method: 'post',
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        console.error('Error during fetch:', response.status);
        throw new Error('Network response was not ok.');
      }
  
      const data = await response.json();
      setBranch(data.Status)
      console.log('Received response:', data);
  
      if (data.StatusResult === 'success') {
       
        setLoading(false);
        console.log("success")
        navigate("./home");
      } else {
        setLoading(false);
        console.log('Login unsuccessful', data.StatusResult);
        setError(true);
      }
    } catch (error) { 
      setError(true);
      console.error('Error during fetch:', error);
      setLoading(false); // Make sure to set loading state to false in case of an error
      // Set error state to true to indicate an error occurred
    }
  };
  
  
  
  const Logo = require("../../Assets/Logo/New.png");
  const SubLogo = require("../../Assets/Logo/Tech.png");
  const User = require("../../Assets/Login/UserName.png");
  const Pass = require("../../Assets/Login/Pass.png");
  const Web = require("../../Assets/Login/Web.png");

  return (
    <>
      <div className="container">
        <img width={120} height={50} className="Logo" src={Logo} alt="New Logo" />
        <img width={100} height={50} className="sub-Logo" src={SubLogo} alt="New Logo" />
        <div className="main-card">
          <h5 className='login-text'>login</h5>
          <Form className="login-form">
            <div>
              <img width={25} height={25} src={User} alt="New Logo" />
              <FormLabel className=''>Username</FormLabel>
              <input onChange={(e) => setEmail(e.target.value)} type="text" className="form-control form-input" />
            </div>
            <div>
              <img width={25} height={25} src={Pass} alt="New Logo" />
              <FormLabel>Password</FormLabel>
              <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control form-input" />
            </div>
            {error && 
              <p className="error">Invalid Credential</p>
            }
            <Button onClick={handleSubmit} className="login-button">Login</Button>
            {/* <Button onClick={()=>navigate("/home")} className="login-button">Login</Button> */}
            <div onClick={() => window.open("https://gsk-sg.com/#")} className='d-flex flex-end justify-content-end'>
              <img width={15} height={15} src={Web} alt="New Logo" /> 
              <span className="web-link">Visit our website</span>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
};

export default LoginPage;
