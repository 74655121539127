import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import NavBar from "../Layout/NavBar/index"
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../Context/AuthContext';
import { Button, Modal } from 'react-bootstrap';

export default function ProductPage() {
  const navigate = useNavigate()
  const { productData, showSearchIcon, branch, email, setProductData, noData } = useContext(AuthContext)

  const [editableQtyIndex, setEditableQtyIndex] = useState(null);
  const [userInputValue, setUserInputValue] = useState('');
  const [orderBtn, setOrderBtn] = useState(false);
  const Code = require("../../Assets/Product/Code.png")
  const Name = require("../../Assets/Product/Product.png")
  const Category = require("../../Assets/Product/Category.png")
  const Price = require("../../Assets/Product/Price.png")
  const Stock = require("../../Assets/Product/Stock.png")
  const Quantity = require("../../Assets/Product/Quantity.png")
  const Req = require("../../Assets/Product/Req.png")
  const NoList = require("../../Assets/Product/Close.gif")
  const [searchQuery, setSearchQuery] = useState('');
  const [productCode, setProductCode] = useState(null);
  const [supplierCode, setSupplierCode] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [poNum, setPoNum] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(productData);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [showData, setShowNoData] = useState(false)
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(false)
 

  const handleShow = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
    navigate("/home")
  }

  const OrderClose = () => {
    setShow(false);
    setEditableQtyIndex(null);
  }

  useEffect(() => {
    const timerID = setInterval(() => {
      const now = new Date();
      setCurrentDateTime(formatDateTime(now));
    }, 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);

  function formatDateTime(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const isSelected = (row) => {
    return selectedRows.some(selectedRow => selectedRow.ProductCode === row.ProductCode);
  };

  const handleQtyBlur = (event, index) => {
    setEditableQtyIndex(null);
  };

  const handleSearchInputChange = event => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = productData.filter(product =>
      product.Name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredProducts(filtered);
    setShowNoData(filtered.length === 0);
  };

  const handleQuantityClick = (index) => {
    setEditableQtyIndex(index);
    setOrderBtn(true);
    const selectedRow = filteredProducts[index];

    if (!isSelected(selectedRow)) {
      const updatedSelectedRows = [...selectedRows, selectedRow];
      setSelectedRows(updatedSelectedRows);
    }
  };

  const handleQtyChange = (event, index) => {
    const { value } = event.target;
    setUserInputValue(value);
    const updatedProducts = [...filteredProducts];
    updatedProducts[index].requiredQty = parseInt(value) || 0;
    setFilteredProducts(updatedProducts);
  };

  const handleRowClick = (index, supplier, productCode, branchId, Ponum) => {
    console.log("Clicked Product Code:", productCode, supplier);
    setProductCode(productCode)
    setSupplierCode(supplier)
    setBranchId(branchId)
    setPoNum(Ponum)
    handleQuantityClick(index);
  };

 
  const totalSelectedProducts = selectedRows.filter(row => row.requiredQty > 0).length;


  const OrderQty = async (selectedRows) => {

    const rowsToOrder = selectedRows.filter(row => row.requiredQty > 0);
    let currentDate = new Date();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let year = String(currentDate.getFullYear()).slice(-2);
    let PONum = "PO" + branchId + month + year + "000" + (parseInt(poNum) + 1);

    try {
       const requestBody = rowsToOrder.map(product => ({
      Branch: branch,
      Supplier: product.SupplierCode,
      UserID: email,
      ProductCode: product.ProductCode,
      QTY: 0,
      RQTY: product.requiredQty,
      Date: currentDateTime,
      Status: "Insert",
      PONumber: PONum
    }));

      const response = await fetch("http://118.189.74.190:1015/api/orderQtyApiMulti", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const data = await response.json();
        if (data.Status === "Success") {
          handleShow();
        } else {
          // Handle failure
        }
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };

  return (
    <>
      <NavBar />
      {noData === "No Data Found" ? (
        <div style={{ marginTop: "200px" }}>
          <div className="d-flex flex-column align-items-center justify-content-center align-self-center">
            <img width={100} height={100} src={NoList} alt="Category Code" />
            <p className="noData">No Product Available</p>
          </div>
        </div>
      ) : (
        <>
          <div className="product-container">
            <div className="card-table w-100">
              <div className="d-flex justify-content-center">
                <div className="search-container">
                  <input
                    placeholder="Search products..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="input" type="text" />
                  <svg viewBox="0 0 24 24" className="search__icon">
                    <g>
                      <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
                      </path>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3 ">
                {orderBtn && userInputValue ? (
                  <div className="d-flex ">
                    <div className="displayBtn" onClick={() => { setDisplay(true) }}>
                      <p className="text">   View selected</p>
                    </div>
                    <button onClick={() => OrderQty(selectedRows)} className="CartBtn mx-3">
                      <span className="IconContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="rgb(17, 17, 17)" className="cart"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path></svg>
                      </span>
                      <p className="text"> Order</p>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="selected-rows-container"></div>
              {selectedRows.length > 0 && display ? (
                <div className="selected-rows-container">
                  <table className='d-flex justify-content-center'>
                    <tbody>
                    {totalSelectedProducts > 0 && display && (
                <div className="selected-rows-container">
                  <h6 style={{ color: "blue", textDecoration: "underline" }} className='d-flex justify-content-center'>Total Selected Products: {totalSelectedProducts}</h6>
                </div>
              )}
                      {selectedRows.filter(row => row.requiredQty > 0).map(row => (
                        <tr key={row.ProductCode} className="d-flex flex-row">
                          <td className="card-content">
                            <h6 className="card-content-name">{row.Name}</h6>
                          </td>
                          <td className="card-content">{row.CategoryCode}</td>
                          <td className="card-content">{row.Price * row.requiredQty}</td>
                          <td className="card-content">{row.requiredQty}</td>
                        </tr>
                      ))}
                      <tr className="d-flex flex-row justify-content-center tot-price">
                        <td className="">Total Price:</td>
                        <td className="">
                          {selectedRows.filter(row => row.requiredQty > 0).reduce((total, row) => total + row.Price * row.requiredQty, 0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
              <div className="card-row w-100 ">
                <table>
                  <thead>
                    <tr className="card-hea">
                      <th className="card-content-hea">
                        <div className="header-content">
                          <img width={20} height={20} src={Code} alt="Product Code" />
                          Product Code
                        </div>
                      </th>
                      <th style={{marginLeft:"100px"}} className="card-content-hea">
                        <div className="header-content">
                          <img width={20} height={20} src={Name} alt="Product Name" />
                          Product Name
                        </div>
                      </th>
                      <th className="card-content-hea">
                        <div className="header-content">
                          <img width={20} height={20} src={Category} alt="Category Code" />
                          Category Code
                        </div>
                      </th>
                      <th className="card-content-hea">
                        <div className="header-content">
                          <img width={20} height={20} src={Price} alt="Price" />
                          Price
                        </div>
                      </th>
                      <th className="card-content-hea">
                        <div className="header-content">
                          <img width={20} height={20} src={Req} alt="Required Quantity" />
                          Order Quantity
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    {showData == true ? (
                      <p className="noData">No data found for "{searchQuery}"</p>
                    ) : null}
                    {filteredProducts.map((product, index) => (
                      <tr onClick={() => { handleQuantityClick(index); handleRowClick(index, product.SupplierCode, product.ProductCode, product.BranchID, product.PONo) }} className="card" key={index}>
                        <td className="card-content">{product.ProductCode}</td>
                        <td className="card-content">
                          <h6  className="card-content-name-table">{product.Name}</h6>
                        </td>
                        <td className="card-content">{product.CategoryCode}</td>
                        <td className="card-content">{product.Price}</td>
                        <td className="card-content">
                          {editableQtyIndex === index ? (
                            <input
                              className='input-box'
                              type="number"
                              min="1"
                              value={product.requiredQty}
                              onChange={(event) => handleQtyChange(event, index)}
                              onBlur={(event) => handleQtyBlur(event, index)} 
                              autoFocus
                            />
                          ) : (
                            <span>{product.requiredQty}</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Modal className='' size="sm" centered show={show} onHide={handleClose}>
                  <Modal.Header >
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body  >
                    <p className='modal-success'>Ordered Successfully</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
