import React, { useContext, useState } from 'react';
import './style.css';
import { useNavigate } from 'react-router-dom';
import NavBar from "../Layout/NavBar/index"
import AuthContext from '../../Context/AuthContext';

export default function ProductPage() {
  const navigate = useNavigate();
  const { branch, productData, setProductData, setShowSearchIcon ,setNoData} = useContext(AuthContext);



  const Shampoo = require('../../Assets/Home/Shampoo.png');
  const Hair = require('../../Assets/Home/Hair.png');
  const Cream = require('../../Assets/Home/Cream.png');
  const Wax = require('../../Assets/Home/Wax.png');
  const Color = require('../../Assets/Home/Color.png');
  const All = require('../../Assets/Home/All.png');


  const ProductReceived = async (key) => {
    if (key === "") {

      setShowSearchIcon(true);
    } else {
      setShowSearchIcon(false);
    }
    try {



      const requestBody = {
        BranchCode: branch,
        Keyword: key
      }

      const response = await fetch("http://118.189.74.190:1015/api/productreceived", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const data = await response.json();
        setNoData(data.Message)
        setProductData(data.productReceived)
        navigate("/home/product", { state: { productData } });




      } else {
        
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };



  return (
    <>
      <NavBar />
      <div style={{ marginTop: "50px" }}>

      </div>
      <div className="Cate-container">

        <div className="cate-name">

          {/* <div className="product-container-home">
            <img
              onClick={() => ProductReceived("hair")}
              className="product"
              src={Hair}
              alt="product"
            />
            <div className="product-details">
              <p>Hair</p>

            </div>
          </div> */}
          {/* <div className="product-container-home">
            <img
              onClick={() => ProductReceived("shampoo")}
              className="product"
              src={Shampoo}
              alt="product"
            />
            <div className="product-details">
              <p>Shampoo</p>

            </div>
          </div>
          <div className="product-container-home">
            <img
              onClick={() => ProductReceived("cream")}
              className="product"
              src={Cream}
              alt="product"
            />
            <div className="product-details">
              <p>Cream </p>

            </div>
          </div>
          <div className="product-container-home">
            <img
              onClick={() => ProductReceived("wax")}
              className="product"
              src={Wax}
              alt="product"
            />
            <div className="product-details">
              <p>Wax</p>

            </div>
          </div>
          <div className="product-container-home">
            <img
              onClick={() => ProductReceived("color")}
              className="product"
              src={Color}
              alt="product"
            />
            <div className="product-details">
              <p>Hair Color</p>

            </div>
          </div> */}
          <div className="product-container-home">

            <img
              onClick={() => ProductReceived("")}
              className="product"
              src={All}
              alt="product"
            />
            <div className="product-details">
              <p>All</p>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
