
import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./Context/AuthContext";


ReactDOM.render(
  <React.StrictMode>
     <AuthProvider>
     <App />
     </AuthProvider>
  
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
