import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from '../Pages/LoginPage/index';
import HomePage from '../Pages/HomePage/index';
import ProductPage from '../Pages/ProductPage/index';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/home/product" element={<ProductPage />} />

      </Routes>
    </Router>
  );
}

export default App;
