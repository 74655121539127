import React, { createContext, useState, useEffect } from 'react';


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const [email, setEmail] = useState(() => {
    // Retrieve email from localStorage if available
    const savedEmail = localStorage.getItem('email');
    return savedEmail ? savedEmail : '';
  });

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);
  const [productData, setProductData] = useState(() => {
    // Retrieve productData from localStorage if available
    const savedProductData = localStorage.getItem('productData');
    try {
      return savedProductData ? JSON.parse(savedProductData) : [];
    } catch (error) {
      console.error('Error parsing product data:', error);
      return []; // Return empty array as default value
    }
  });

  useEffect(() => {
    if (productData) {
      localStorage.setItem('productData', JSON.stringify(productData));
    }
  }, [productData]);
  
  const [password, setPassword] = useState('');
  const [branch, setBranch] = useState(() => {
    const savedBranch = localStorage.getItem('branch');
    return savedBranch ? savedBranch : ''; // If savedBranch is null, return an empty string
  });

  useEffect(() => {
    localStorage.setItem('branch', branch);
  }, [branch]);

  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [noData, setNoData] = useState("");

  // Load data from local storage on component mount
  useEffect(() => {
    const savedData = localStorage.getItem('noData');
    if (savedData) { 
      setNoData(savedData);
    }
  }, []); // Empty dependency array means this effect runs only once on component mount

  // Update local storage whenever noData changes
  useEffect(() => {
    localStorage.setItem('noData', noData);
  }, [noData]);

  const contextValue = {
    email,
    setEmail,
    password,
    setPassword,
    setBranch,
    branch,
    setProductData,
    productData,
    setShowSearchIcon,
    showSearchIcon,
    setNoData,
    noData

  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
