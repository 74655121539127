import React, { useContext, useEffect, useState } from 'react';
import "./style.css"
import AuthContext from '../../../Context/AuthContext';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface TimeZone {
  label: string;
  timeZone: string;
  currentTime: string; // Add currentTime property
}
export default function Index() {
  const navigate =useNavigate()
  // const { productData } = useContext(AuthContext)
  // const { BranchID } = productData[0];
  const Time = require("../../../Assets/Nav/Time.gif");
  const Office = require("../../../Assets/Nav/Office.png");
  const Logo = require("../../../Assets/Logo/New.png");

  const [timeZones, setTimeZones] = useState<TimeZone[]>([ // Define TimeZone array type
    { label: 'Singapore', timeZone: 'Asia/Singapore', currentTime: '' },
    // { label: 'India', timeZone: 'Asia/Kolkata', currentTime: '' },
    // { label: 'Indonesia', timeZone: 'Asia/Jakarta', currentTime: '' },
    // { label: 'Malaysia',timeZone: 'Asia/Kuala_Lumpur', currentTime: '' },
  ]);

  useEffect(() => {
    const timerID = setInterval(() => updateClocks(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, []);

  const updateClocks = () => {
    setTimeZones(timeZones.map(zone => ({
      ...zone,
      currentTime: new Date().toLocaleString('en-US', { timeZone: zone.timeZone })
    })));
  };



  return (
    <div className="nav-bar ">

      {timeZones.map((zone, index) => (

        <div key={index} className="clock">
          
          <div className="time">
            <img src={Time} alt="GIF Image" />
            {zone.currentTime.split(' ')[1]}</div>

        </div>
      ))}
      <div>
        <img className='img-nav-logo ' src={Logo} alt="New Logo" />
      </div>
      <div>
  <h6 onClick={() => navigate("/")} style={{marginRight:"5px",textDecoration:"underline",cursor:"pointer"}}>Logout</h6>
</div>



    </div>





  )
}
